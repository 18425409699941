<template>
  <el-dialog
    id="EditRestrictSKUDialog"
    :title="title"
    :visible.sync="dialogShow"
    center
    modal
    top="5vh"
    :close-on-click-modal="false"
    width="496px"
    close-on-press-escape
    class="my-dialog abow_dialog"
    @opened="onShow"
    @keydown.enter.native="onCommit"
    destroy-on-close
  >
    <el-form ref="EditRestrictSKUForm" :model="model" :rules="rules" @submit.native.prevent="onSubmit" v-loading="loading">
      <el-row v-if="loading" justify="center" align="middle" type="flex" style="height:220px">
        <el-empty :image-size="100" :image="require('../../../assets/icons/no_data.svg')"></el-empty>
      </el-row>
      <el-row v-else>
        <el-form-item prop="sku" :label="$t('Назва')">
          <el-input v-model="model.sku" ref="nameInput" autocomplete="off" readonly></el-input>
        </el-form-item>
        <el-row justify="space-between" type="flex">
          <el-col :span="11">
            <el-form-item prop="cip" :label="cipPriceWithCurrency">
              <el-input v-model="model.cip" ref="cipInput" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="cipUAH" :label="$t('CIP ціна, грн.')">
              <el-input v-model="model.cipUAH" ref="cipUAHInput" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-between" type="flex">
          <el-col :span="11">
            <el-form-item prop="bonus" :label="bonusWithType">
              <el-input v-model="model.bonus" ref="bonusInput" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="priority" :label="$t('Приорітет')">
              <el-select style="width:100%" v-model="model.priority" :placeholder="$t('Не обрано')">
                <el-option v-for="item in selectOptions.sku.priority" :key="item.value" :label="$t(item.label)" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button">{{ $t("Застосувати") }}</el-button>
      <el-button type="text" @click="dialogShow = false" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { bus } from "@/main";
  import { _ } from "vue-underscore";

  export default {
    name: "EditRestrictSKU",
    components: {},
    data() {
      var that = this;
      var defaultValidateRule = {};
      var digitalValueValidateRule = {
        validator: (rule, value, callback) => {
          if (value == undefined || value == "") {
            callback();
          } else if ((!that.isNumeric(value) && !that.isNumeric(value.split(",").join("."))) || value < 0) {
            callback(new Error("Поле має бути невід'ємним числом"));
          } else {
            callback();
          }
        },
      };
      return {
        loading: false,
        dialogShow: false,
        title: $tt("Редагування налаштувань лікарського засобу"),
        operation: null,
        rules: {
          cip: [digitalValueValidateRule],
          cipUAH: [digitalValueValidateRule],
          bonus: [digitalValueValidateRule],
        },
        model: {},
        initModel: {},
      };
    },

    methods: {
      onCommit() {
        this.$refs.EditRestrictSKUForm.validate((valid) => {
          if (valid) {
            this.$emit("commit", { operation: this.operation, model: this.prepareForCommit(this.model) });
          } else {
            this.$message.error($tt("Перевірте введені дані!"));
          }
          return false;
        });
      },
      onShow() {
        this.$refs.EditRestrictSKUForm.resetFields();
        this.model = _.extend({}, this.initModel);
        this.$nextTick(() => {
          this.loading = false;
          this.$nextTick(() => {
            this.$refs.cipInput.focus();
          });
        });
      },
      prepareForCommit(data) {
        data.cip = data.cip && !this.isNumeric(data.cip) ? data.cip.split(",").join(".") : data.cip;
        data.cipUAH = data.cipUAH && !this.isNumeric(data.cipUAH) ? data.cipUAH.split(",").join(".") : data.cipUAH;
        data.bonus = data.bonus && !this.isNumeric(data.bonus) ? data.bonus.split(",").join(".") : data.bonus;
        return data;
      },
      nextFocus(next) {
        if (next == "commit") {
          this.onCommit();
        } else this.$refs[next].focus();
      },
    },
    created() {
      bus.$on("forms.rules.sku.edit.show", (data) => {
        this.loading = true;
        this.title = (data && data.title) || this.title;
        this.operation = (data && data.operation) || "create";
        this.initModel = _.extend({}, data ? data.initModel : {});
        this.dialogShow = true;
      });
      bus.$on("forms.rules.sku.edit.hide", () => {
        this.dialogShow = false;
      });
    },
    computed: {
      cipPriceWithCurrency() {
        return $tt("CIP ціна") + ", " + this.$store.getters["contracts/contract"]().currency;
      },
      bonusWithType() {
        var bonusMeasure = this.$store.getters["restricts/restrict"]().bonusMeasure;
        if (bonusMeasure) {
          var option = this.selectOptions.restricts.bonusMeasure.find((option) => option.value == bonusMeasure);
          if (option) return $tt("Бонус, ") + $tt(option.label);
          else return $tt("Бонус, /{mes}/", { mes: bonusMeasure });
        } else return $tt("Бонус, /.../");
      },
      selectOptions() {
        return this.$store.getters["main/selectionOptions"];
      },
    },
  };
</script>
<style lang="scss">
  #EditRestrictSKUDialog {
    .el-dialog {
      max-height: 360px !important;
    }
  }
</style>
